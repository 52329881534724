export const structuredData = {
  home1: {
    '@context': 'https://www.schema.org',
    '@type': 'WebSite',
    name: 'Cubet Techno Labs',
    alternateName: 'Cubet',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    description:"Cubet: Your Digital Transformation Partner. We offer Custom Software Development + AI solutions for businesses, ensuring exceptional experiences on mobile, cloud, and web platforms.",
  },
  home2: {
    "@context": "https://www.schema.org",
    "@type": "Organization",
    name: "Cubet Techno Labs",
    alternateName: "Cubet",
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: "https://cubettech.com/_next/image/?url=%2Fassets%2Flogo%2Fcubet-logo-with-text.svg&w=384&q=75",
    address: [
      {
        "@type": "PostalAddress",
        streetAddress: "6595 Roswell Road, Suite G2007 Atlanta",
        addressLocality: "Atlanta",
        addressRegion: "Georgia",
        postalCode: "30328",
        addressCountry: "United States"
      },
      {
        "@type": "PostalAddress",
        streetAddress: "Unit IX-C, 9th floor, Phase IV",
        addressLocality: "Kochi",
        addressRegion: "Kerala",
        postalCode: "682030",
        addressCountry: "India"
      }
    ],
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: "+91 484 4054324",
        contactType: "customer service",
        areaServed: "IN",
        availableLanguage: "en"
      },
      {
        "@type": "ContactPoint",
        telephone: "+1 (678) 701-4895",
        contactType: "customer service",
        areaServed: "US",
        availableLanguage: "en"
      }
    ],
    sameAs: [
      "https://www.facebook.com/CubetTech/",
      "https://twitter.com/CubetTech",
      "https://www.instagram.com/cubettechnolabs/",
      "https://www.linkedin.com/company/cubet-technologies",
      "https://www.youtube.com/c/CubetTech"
    ]
  },
  blog1: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/resources/blog/`,
    name: 'Blog | Our perspective on technology | Cubet',
    description:
      'Explore our blog for insightful articles, industry trends, and updates. Our blog covers a wide range of topics including technology, software development, digital transformation, and more. Stay up-to-date with the latest insights from Cubet Techno Labs!',
  },
  blog2: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Cubet Techno Labs',
    alternateName: 'Cubet Tech',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: 'https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+91 484 4054324',
        contactType: 'customer service',
        areaServed: 'IN',
        availableLanguage: 'en',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+1 (678) 701-4895',
        contactType: 'customer service',
        areaServed: 'US',
        availableLanguage: 'en',
      },
    ],
    sameAs: [
      'https://www.facebook.com/CubetTech/',
      'https://twitter.com/CubetTech',
      'https://www.instagram.com/cubettechnolabs/',
      'https://www.linkedin.com/company/cubet-technologies',
      'https://www.youtube.com/c/CubetTech',
    ],
  },
  career1: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/cubet-career/`,
    name: 'Careers | We are hiring | Discover oppurtunities | Cubet',
    description:
      'Explore exciting career opportunities at Cubet Techno Labs. We are constantly looking for talented individuals to join our team. Check out our current job openings and apply today to become part of our innovative and dynamic workforce!',
  },
  career2: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Cubet Techno Labs',
    alternateName: 'Cubet Tech',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/cubet-career/`,
    logo: 'https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+91 484 4054324',
        contactType: 'customer service',
        areaServed: 'IN',
        availableLanguage: 'en',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+1 (678) 701-4895',
        contactType: 'customer service',
        areaServed: 'US',
        availableLanguage: 'en',
      },
    ],
    sameAs: [
      'https://www.facebook.com/CubetTech/',
      'https://twitter.com/CubetTech',
      'https://www.instagram.com/cubettechnolabs/',
      'https://www.linkedin.com/company/cubet-technologies',
      'https://www.youtube.com/c/CubetTech',
    ],
  },
  service1: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: 'URL',
    name: 'Titile Tag',
    description: 'Meta Desc',
  },
  service2: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Cubet Techno Labs',
    alternateName: 'Cubet Tech',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: 'https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+91 484 4054324',
        contactType: 'customer service',
        areaServed: 'IN',
        availableLanguage: 'en',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+1 (678) 701-4895',
        contactType: 'customer service',
        areaServed: 'US',
        availableLanguage: 'en',
      },
    ],
    sameAs: [
      'https://www.facebook.com/CubetTech/',
      'https://twitter.com/CubetTech',
      'https://www.instagram.com/cubettechnolabs/',
      'https://www.linkedin.com/company/cubet-technologies',
      'https://www.youtube.com/c/CubetTech',
    ],
  },
  about1: {
    '@context': 'https://www.schema.org',
    '@type': 'WebPage',
    name: 'Cubet About Us',
    alternateName: 'Cubet Tech',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/about-us/`,
    description:
      'Looking for IT consulting services? Cubet is a global leader in next generation solutions, services and consulting, with over decades of experience. Visit our page to learn more about our services!',
  },
  about2: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Cubet Techno Labs',
    alternateName: 'Cubet Tech',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: 'https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+91 484 4054324',
        contactType: 'customer service',
        areaServed: 'IN',
        availableLanguage: 'en',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+1 (678) 701-4895',
        contactType: 'customer service',
        areaServed: 'US',
        availableLanguage: 'en',
      },
    ],
    sameAs: [
      'https://www.facebook.com/CubetTech/',
      'https://twitter.com/CubetTech',
      'https://www.instagram.com/cubettechnolabs/',
      'https://www.linkedin.com/company/cubet-technologies',
      'https://www.youtube.com/c/CubetTech',
    ],
  },
  about3: {
    '@context': 'https://www.schema.org',
    '@type': 'WebPage',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/solutions/`,
    name: 'Artificial Intelligence (AI), Machine Learning, IoT Services & Solutions | Cubet',
    description:
      'Cubet offers the top-notch Artificial Intelligence (AI), Machine Learning, the Internet of Things (IoT) Services & Solutions. We build solutions that utilize our technical expertise and experience for solving domain-specific business challenges. Contact us today to know more!!',
  },
  about4: {
    '@type': 'AggregateRating',
    '@bestRating': '5',
    '@awards': 'Laravel Partner Company',
  },
  industries1: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/industries/`,
    name: 'Industries We Serve | Cubet Techno Labs',
    description:
      'Cubet Techno Labs serves various industries by providing innovative software solutions. Our expertise extends to industries such as healthcare, finance, e-commerce, education, and more. Contact us today to discuss how we can help your industry thrive!',
  },
  industries2: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Cubet Techno Labs',
    alternateName: 'Cubet Tech',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: 'https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+91 484 4054324',
        contactType: 'customer service',
        areaServed: 'IN',
        availableLanguage: 'en',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+1 (678) 701-4895',
        contactType: 'customer service',
        areaServed: 'US',
        availableLanguage: 'en',
      },
    ],
    sameAs: [
      'https://www.facebook.com/CubetTech/',
      'https://twitter.com/CubetTech',
      'https://www.instagram.com/cubettechnolabs/',
      'https://www.linkedin.com/company/cubet-technologies',
      'https://www.youtube.com/c/CubetTech',
    ],
  },
  casestudy1: {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/resources/case-studies/`,
    name: 'Case Studies | Success Stories | Cubet Techno Labs',
    description:
      'Explore our case studies and success stories to see how Cubet Techno Labs has helped businesses achieve their goals. Learn about our innovative solutions and the positive impact they have made across various industries. Contact us today to discuss your project!',
  },
  casestudy2: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Cubet Techno Labs',
    alternateName: 'Cubet Tech',
    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
    logo: 'https://cubet-website-storage-live.s3.us-west-2.amazonaws.com/cubet_logo_with_text_4af89a6bd0.svg?AWSAccessKeyId=AKIA3HOWHT4I6KJC7VXP&Expires=1689065076&Signature=1oLYxCoA%2B4G9mPunl3oqekEehC4%3D',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+91 484 4054324',
        contactType: 'customer service',
        areaServed: 'IN',
        availableLanguage: 'en',
      },
      {
        '@type': 'ContactPoint',
        telephone: '+1 (678) 701-4895',
        contactType: 'customer service',
        areaServed: 'US',
        availableLanguage: 'en',
      },
    ],
    sameAs: [
      'https://www.facebook.com/CubetTech/',
      'https://twitter.com/CubetTech',
      'https://www.instagram.com/cubettechnolabs/',
      'https://www.linkedin.com/company/cubet-technologies',
      'https://www.youtube.com/c/CubetTech',
    ],
  },
};
