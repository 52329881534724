import { Transformations } from "@/utils/types";

export const transformation: Transformations = {
  Digitalisation: [
    {
      title: "Application Development",
      descr:
        "We specialize in designing and developing custom softwares that are intelligent and agile to help businesses thrive in a rapidly evolving digital landscape.",
      image: "/assets/images/Product-engineering.jpg",
      list: [
        "Web application development",
        "Native Mobile application development",
        "Hybrid app development",
        "PWA Development",
      ],
      link: "/services/application-development",
    },
    {
      title: "Product Engineering",
      descr:
        "With our time-tested blend of technological expertise and creative concepts, we can design and devise smart products that can tackle problems and produce tangible benefits.",
      image: "/assets/images/ApplicationDevelopments.jpg",
      list: [
        "Custom Product Engineering",
        "Product Rescue",
        "Outsourced Product development",
        "Legacy software modernization",
      ],
      link: "/services/Product-engineering",
    },
    {
      title: "IT Staff Augmentation",
      descr:
        "Our IT staff augmentation services provide your business with a well-equipped pool of IT personnel who can help collaborate with your team and processes and also facilitate end-to-end software development.",
      image: "/assets/images/team-augmentation.jpg",
      list: [
        "Full Stack Team Augmentation",
        "Specialized Skill Augmentation",
        "Hybrid Team Augmentation",
      ],
      link: "/services/staff-augmentation",
    },
    {
      title: "Enterprise Solution Integration",
      descr:
        "Integrate Workato, Boomi, MuleSoft,Microsoft Dynamics 365 and Salesforce into your enterprise to amplify business efficiency, delivering enhanced productivity and streamlined operations",
      image: "/assets/images/enterprise-solution.jpg",
      list: ["Workato", "Boomi","MuleSoft","Salesforce", "Microsoft Dynamics 365"],
      link: "/services/enterprise-solution-integration",
    },
  ],
  "Data & AI": [
    {
      title: "Machine Learning",
      descr:
        "Our ML solutions are aimed at building, training, deploying, and fine-tuning learning models. These functionalities are built with the help of trusted libraries and tools, as well as custom algorithms.",
      image: "/assets/images/Machine-learning.jpg",
      list: [
        "ML Model Development",
        "Predictive Analysis",
        "Natural Language Processing",
        "Deep Learning Development",
        "MLOps Consulting"
      ],
      link: "/services/machine-learning",
    },
    {
      title: "Artificial Intelligence",
      descr:
        "Our AI services can assist you in incorporating intelligence into your business processes. It can enhance efficiency by automating and streamlining operations, thereby creating improved value for your business.",
      image: "/assets/images/AI.jpg",
      list: [
        "Generative AI",
        "Conversational AI",
        "Open GPT",
        "Vision Analytics",
        "Computer Vision",
      ],
      link: "/services/artificial-intelligence",
    },
    {
      title: "RPA",
      descr:
        "Our Robotic Process Automation solutions aids in eliminating the need for surplus manpower to perform repetitive and mundane tasks. This in turn, helps businesses improve overall efficiency and accuracy.",
      image: "/assets/images/RPA.jpg",
      list: [
        "Intelligent Automation Services",
        "RPA Development",
        "Internet of Things Development ",
      ],
      link: "/services/robotic-process-automation",
    },
    {
      title: "Data Analytics",
      descr:
        "With our comprehensive data analysis expertise, accumulated and generated data can be processed and converted into meaningful insights that can help you make informed decisions and thereby increase your business revenue.",
      image: "/assets/images/Data-analytics1.jpg",
      list: [
        "Data Visualization",
        "Data Warehousing",
        "Power BI",
        "Data Governance",
      ],
      link: "/services/data-analytics",
    },
  ],
  Cloud: [
    {
      title: "Cloud Transformation",
      descr:
        "Our team of cloud experts is dedicated to helping you select the optimal cloud services that best align with your infrastructure, software, and service needs.",
      image: "/assets/images/cloud-service1.jpg",
      list: [
        "Cloud Architecture and Design",
        "Cloud Migration",
        "Cloud-Native Development",
        "Cloud Integration",
        "Cloud Data & AI",
      ],
      link: "/services/cloud-transformation-services",
    },
    {
      title: "DevOps As a Service",
      descr:
        "Our DevOps services can streamline and automate the software delivery process by implementing CI/CD pipelines, and managing infrastructure as code.",
      image: "/assets/images/Devops-as-a-service1.jpg",
      list: [
        "DevOps assessment",
        "DevOps automation",
        // "DevOps management",
        "CI/CD",
      ],
      link: "/services/devOps-as-a-service",
    },
    {
      title: "Maintenance",
      descr:
        "We offer maintenance services to ensure secure and seamless application operations with proactive server monitoring, prompt patching, upgrading, and enhancements to minimize downtimes.",
      image: "/assets/images/maintenance.jpg",
      list: ["Managed support", "Pay-as-you-go support", "Emergency support"],
      link: "/services/cloud-maintenance",
    },
    {
      title: "Cloud Services",
      descr:
        "We automate time-consuming tasks such as container creation/scaling, network/storage management, and orchestration for easy resource allocation, asset relocation, and load balancing.",
      image: "/assets/images/container-orchestration1.jpg",
      list: ["Kubernetes", "Docker SWARM","AWS","Azure","GCP"],
      link: "/services/cloud-services",
    },
  ],
  Consulting: [
    {
      title: "Digital Transformation Consulting",
      descr:
        "We help businesses navigate the challenges of digital transformation with strategies that match their business goals. We help them with easy integration of the latest technology, crucial insights, and measurable results.",
      image: "/assets/images/digital-transformation-consulting.jpg",
      list: [
        "Agile Transformation",
        "Application Modernization",
        "RPA Implementation",
        "BlockChain",
      ],
      link: "/services/digital-transformation-consulting",
    },
    {
      title: "Product Consulting",
      descr:
        "With our expertise across industries, we help businesses identify innovative product strategies focused on delivering delightful experiences, solving critical problems, and ensuring success.",
      image: "/assets/images/product-consulting.jpg",
      list: [
        "Product Strategy ",
        "User Experience (UX) Design ",
        "Product Requirements Definition",
        "QA as a Service"
      ],
      link: "/services/product-consulting",
    },
    {
      title: "DevOps Consulting",
      descr:
        "We help create DevOps strategies for enterprises based on lean and agile principles that can eliminate traditional barriers to allow faster and more secure software releases.",
      image: "/assets/images/Devops-consulting1.jpg",
      list: [
        "DevOps Assessment and Strategy",
        "Cloud Adoption and Migration",
        "Continuous Integration and Continuous Delivery (CI/CD) Pipeline",
      ],
      link: "/services/devOps-consulting",
    },
    {
      title: "AI Consulting",
      descr:
        "We design advanced AI approaches for businesses to make use of machine learning, neural networks, and deep learning techniques. This helps in building applications that can perform specific tasks for your business.",
      image: "/assets/images/AIConsulting.jpg",
      list: [
        "Strategy and planning",
        "Enterprise AI Solutions",
        "Data Analysis and Modeling",
      ],
      link: "/services/artificial-intelligence-consulting",
    },
  ],
};
export const whyChoose = [
  {
    title: "Our Approach",
    descr:
      "We adopt a highly collaborative and tech-driven approach to working with our clients. Doing so enables us to create accurate outcomes, minimise redundancies, and maintain the highest quality standards.",
    image: "/assets/images/our-approach-new.jpg",
    width: 4096,
    height: 2732,
    list: [
      {
        title: "Co-creation Work Model",
        descr:
          "At the heart of our approach is a synergistic co-creation work model that brings together our clients and our in-house experts to achieve desired outcomes.",
      },
      {
        title: "Agile and Purpose based Development",
        descr:
          "We use rapid sprints with micro- and macro-objectives to turn projects around in record time. Our mission is to drive customer lifetime value, and that is our constant focus.",
      },
    ],
  },
  {
    title: "Engagement Models",
    descr:
      "We have carefully created multiple engagement models to meet the diverse demands of clients of all sizes. We also assist our clients in choosing the best model for their immediate and long-term objectives.",
    image: "/assets/images/engagement.png",
    width: 1366,
    height: 911,
    list: [
      {
        title: "Fixed Price Model",
        descr:
          "If your requirements are fixed, and you have a defined budget and time limits, the 'Fixed Price' model is best-suited for you. We take care of all your requirements, that is, from documentation to deployment.",
      },
      {
        title: "T&M and Dedicated Models",
        descr:
          "Time & Material, offering flexibility based on hourly rates for projects with uncertain timelines, and Dedicated Team, providing long-term involvement with a monthly fee for a committed team.",
      },
    ],
  },
  {
    title: "Innovative Culture",
    descr:
      "We are proud of our work environment, which fosters and encourages creativity, experimentation, and the generation of new ideas. We encourage you to take risks, think outside the box, and challenge the normal.",
    image: "/assets/images/innovative.jpg",
    width: 4096,
    height: 2732,
    list: [
      {
        title: "Embracing Emerging Technologies",
        descr:
          "We quickly adopt new technologies and add them to our business processes to gain a competitive advantage, improve efficiency, and meet the needs of our clients.",
      },
      {
        title: "Experimentation and Agility",
        descr:
          "Testing new ideas through small-scale POCs helps us make informed decisions before scaling up. We also break down projects into smaller tasks, prioritising the higher-priority ones, and then the rest.",
      },
    ],
  },
];
export const useCase = [
  {
    heading: "Case Studies",
    content: [
      {
        title: "Enabling World-Class Education for Africa",
        descr:
          "Learn how a digital learning ecosystem is breaking barriers in African education and enabling personalized learning, and real-time progress monitoring.",
        link: "/resources/case-studies/Digitizing-Education-in-Africa",
      },
      {
        title: "Navigating the Challenges of Aviation Training",
        descr:
          "How a dynamic aviation training management ecosystem is built to streamline trainings and provide interactive an learning experiences.",
        link: "/resources/case-studies/Enabling-Smarter-Aviation-Training",
      },
      {
        title: "Improving Healthcare in the Digital Age",
        descr:
          "Enabling a digital healthcare revolution, connecting medical experts worldwide for impactful collaboration and knowledge exchange.",
        link: "/resources/case-studies/Medical-Legacy-Goes-Digital",
      },
      {
        title: "Robotics to Improve Efficiency and Productivity",
        descr:
          "Learn how Industrial automation is undergoing a remarkable transformation powered by robotics, AI, and IoT innovations.",
        link: "/resources/case-studies/advanced-robotics-for-automations",
      },
      {
        title: "AI for Accurate, Reliable, and Accessible News",
        descr:
          "How an AI-powered platform is combating fake news, empowering users with accurate information, and promoting media literacy.",
        link: "/resources/case-studies/ai-for-enabling-fact-checking",
      },
      {
        title: "Helping Coaches Make Better Decisions in Sports",
        descr:
          "How is sports coaching improved with advanced analytics, interactive dashboards, and automated scouting for data-backed decision-making.",
        link: "/resources/case-studies/improving-decision-making-in-sports",
      },
    ],
  },
  {
    heading: "Industries",
    content: [
      {
        title: "Education",
        descr:
          "With personalized learning, remote access, immersive experiences, increased engagement, monitoring student data etc., we are transforming education with technology.",
        link: "/",
      },
      {
        title: "Healthcare",
        descr:
          "We are helping the healthcare sector by improving patient outcomes through technology aided diagnostics, monitoring, communication, accessibility, and efficiency.",
        link: "/",
      },
      {
        title: "Logistics and Shipping",
        descr:
          "Using AI, DevOps, blockchain, and IoT, we enhance supply chain organization, fleet management, and network visibility for precise decision-making.",
        link: "/",
      },
      {
        title: "Telecom and Networking",
        descr:
          "We specialize in network management, infrastructure optimization, tackling cybersecurity challenges, and supporting telecom providers' growth.",
        link: "/",
      },
      {
        title: "Insurance",
        descr:
          "Our insurance software transforms underwriting, claims processing, and customer interactions, providing solutions that drive compliance and efficiency.",
        link: "/",
      },
      {
        title: "Hospitality",
        descr:
          "With in-depth knowledge of operational management and customer service trends, we integrate technology to optimize efficiency, cost, and guest satisfaction.",
        link: "/",
      },
    ],
  },
];
